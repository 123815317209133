import btnDirective from './btnDirective/btnDirective'
const install = function (Vue) {
    Vue.directive('btnDirective', btnDirective)
}
export default install

// export default {
//     install(Vue){
//         Vue.directive('btnDirective', btnDirective)
//     }
// }