import { Loading } from "element-ui";
let loading;
/**
 * 打开次数
 */
let needLoadingRequestCount = 0;

/**
 * 打开loading
 */
function startLoading () {
  loading = Loading.service({
    // lock: true,
    // text: '加载中……',
    // background: 'rgba(0, 0, 0, 0.7)'
  })
}

/**
 * 关闭loading
 */
function endLoading () {
  needLoadingRequestCount = 0;
  loading.close()
}

/**
 * 开启loading
 */
let showFullScreenLoading = () => {
  if (needLoadingRequestCount === 0) {
    startLoading()
  }
  needLoadingRequestCount++;
}
/**
 * 取消loading
 */
let tryHideFullScreenLoading = () => {
  //if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--;
  if (needLoadingRequestCount <= 0) {
    endLoading()
  }
}

export { showFullScreenLoading, tryHideFullScreenLoading }