import Vue from "vue";
import Vuex from "vuex";
import common from "@/common/common";
Vue.use(Vuex);

//状态
const state = {
  previousTasks: "", //原任务id
  renwuzt: "", // 剪辑任务状态
  openol: "", //聊天框
  active: {}, //当前菜单
  userInfo: {}, //当前登录用户信息
  loginState: false, // 登录状态
  opt: {}, //操作菜单
  reload: false, //重新加载
  detailId: "", //详情ID
  bindWeixin: true, //显示绑定微信
  tabActive: "clip", //客户管理默认tab选项
  islogin: false,
  isloginshow: false,
  taskAdd: true, //true：任务添加 false：任务修改
  aId: "", //主账户id
  noRead: false, //是否有未读
  queryInfo: {
    pageNumber: 1,
    pageSize: 10,
  }, //审核管理默认搜索条件
  rechargeName: "", // shanghu 或者是 zhuzhanghu
  merchantList: [], //客户列表不分页
  add: "tianjia",
  rwzffs: "4", //任务支付方式
  renwzhuant: "",
  downonline: false,
  startsnum: true,
  yestshi: false,

  merTaskId: "",
  onlineTaskId: "",
  taskIdUseDetail: "",
  partTaskIdUseDetail: "",
  socketData: "",
  showBindweChat: false,
  scoketUrl: "",
  showChat: true,
};

// mutations 主要用来操作state
const mutations = {
  resetState(state) {
    //重置
    state = {};
  },
  logout(state, info) {
    state = {};
    state.loginState = false;
    state.reload = true;
    state.bindWeixin = true;
    sessionStorage.clear();
    localStorage.removeItem("businessToken");
    localStorage.removeItem("businessStore");
  },

  saveUserInfo(state, info) {
    //登录用户信息
    state.loginState = true;
    state.userInfo = info;
    state.aId = info.userId;
    localStorage.setItem("businessToken", info.token);
    localStorage.setItem("businessStore", JSON.stringify(state));
  },
  saveNavState(state, item) {
    //当前选择菜单
    state.active = item;
    state.reload = false;
    state.tabActive = "account";
  },
  saveOpt(state, optList) {
    //操作菜单
    state.opt = optList;
  },
  setNavState(state, rou) {
    //路由跳转
    state.active.routing = rou;
    state.reload = true;
  },
  setNavMenuName(state, pathName) {
    //路由跳转保存对应菜单文字信息
    state.active.menuName = pathName;
  },
  saveDetailId(state, val) {
    //保存详情页面id
    state.detailId = val.id;
    state.payType = val.type;
  },
  saveTabActive(state, val) {
    //客户
    state.tabActive = val;
  },
  isLogin(state, val) {
    state.islogin = val;
    state.isloginshow = !val;
  },
  isloginshow(state, val) {
    //保存字典值
    state.isloginshow = val;
  },
  saveDicType(state, val) {
    //保存字典值
    const ddd = val.type;
    state[ddd] = val.data;
  },
  saveMenuTreeList(state, list) {
    state.menuTreeList = list;
  },
  saveStore(state, val) {
    // 信息缓存到本地
    if (localStorage.getItem("businessToken")) {
      localStorage.setItem("businessStore", JSON.stringify(state));
    } else {
      localStorage.setItem("businessStore", "");
    }
  },
  socket_init(state, url) {
    let uuu = `ws://${common.base_Url.slice(7)}${
      common.merSer
    }/webSocket?token=${state.userInfo.token}&${state.userInfo.userId}`;
    state.scoketUrl = uuu;
    /*  console.log(
      `ws://${common.base_Url.slice(7)}${common.merSer}/webSocket?token=${
        state.userInfo.token
      }&${state.userInfo.userId}`
    ); */
    // 创建一个this.socketTask对象【发送、接收、关闭socket都由这个对象操作】
    // let socket = state.socketTask;
    state.socketTask = new WebSocket(uuu);
    // console.log(state.socketTask);
    // 消息的发送和接收必须在正常连接打开中,才能发送或接收【否则会失败】
    state.socketTask.onopen = function () {
      console.log("WebSocket连接正常打开中...！");
      state.is_open_socket = true;
    };
    // 注：只有连接正常打开中 ，才能正常收到消息
    state.socketTask.onmessage = (res) => {
      console.log("收到服务器内容：" + res.data);
      let time = new Date().getTime();
      state.socketData = res.data + ">>>" + time;
    };
    state.socketTask.onclose = function () {
      console.log("WebSocket 已关闭！");
      state.is_open_socket = false;
      state.socketTask.onopen();
    };
    state.socketTask.websocketonerror = function () {
      console.log("WebSocket连接发生错误");
    };
  },
  socket_send(state, p) {
    console.log(p);
    /* state.socketTask.send({
			data: p,
			async success() {
				console.log("消息发送成功");
			},
		}); */
    if (state.is_open_socket) {
      state.socketTask.send(p);
    } else {
      state.socketTask.onopen();
      setTimeout(() => {
        state.socketTask.send(p);
      }, 300);
    }
  },
  socket_close(state, p) {
    if (state.is_open_socket) {
      state.socketTask.onclose({
        success: () => {
          state.is_open_socket = false;
        },
      });
    }
  },
  openFile(state, v) {
    let fileName = v.fileName;
    var ext = fileName.split(".")[fileName.split(".").length - 1];
    if (ext !== "jpeg" && ext !== "png" && ext !== "jpg" && ext !== "gif") {
      let url = v.echoPath;
      var x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = (e) => {
        //会创建一个 DOMString，其中包含一个表示参数中给出的对象的URL。这个 URL 的生命周期和创建它的窗口中的 document 绑定。这个新的URL 对象表示指定的 File 对象或 Blob 对象。
        var url = window.URL.createObjectURL(x.response);
        var a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      };
      x.send();
    } else {
      window.open(v.echoPath, "_blank");
    }
  },
};
const actions = {
  socket_init({ commit }, url) {
    commit("socket_init", url);
  },
  socket_send({ commit }, p) {
    commit("socket_send", p);
  },
  socket_close({ commit }, p) {
    commit("socket_close", p);
  },
  openFile({ commit }, p) {
    commit("openFile", p);
  },
};
export default new Vuex.Store({
  state,
  mutations,
  actions,
});
