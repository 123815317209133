import axios from "axios";
import qs from "qs";
import common from "@/common/common.js";
import { message } from "@/components/resetMessage";
import { errMsg } from "./errMsg";
import { MessageBox } from "element-ui";
import { showFullScreenLoading, tryHideFullScreenLoading } from "./loading";
import router from "@/router";
const service = axios.create({
  baseURL: common.base_Url,
  timeout: 10000,
});
const options = {
  fullscreen: true,
};

service.interceptors.request.use(
  (config) => {
    // Loading.service(options);
    // console.log(config);
    showFullScreenLoading();
    config.headers["Content-Type"] =
      config.contentType || "application/x-www-form-urlencoded";
    // 是否需要设置token
    const token = localStorage.getItem("businessToken");
    if (token) {
      config.headers["token"] = token;
    }
    config.data =
      config.contentType == "application/json"
        ? config.data
        : qs.stringify(config.data);
    config.responseType = config.responseType || "";
    return config;
  },
  (error) => {
    tryHideFullScreenLoading();
    return error;
  }
);
let a = true;
service.interceptors.response.use(
  (res) => {
    tryHideFullScreenLoading();
    // let loadingInstance = Loading.service(options);
    // setTimeout(() => {
    //   loadingInstance.close();
    // }, 300);
    let code = res.data.code;
    if (code == 600) {
      if (a) {
        a = false;
        sessionStorage.clear();
        localStorage.removeItem("businessToken");
        localStorage.removeItem("businessStore");
        router.replace({
          path: "/login",
        });
        MessageBox.confirm(res.data.message, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {})
          .catch(() => {
            a = true;
          });
      }
    } else if (code == 203) {
      message.error(res.data.message);
    } else if (code == 302) {
      message.error(res.data.message);
    } else if (code == 101) {
      MessageBox.confirm(res.data.message, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        showCancelButton: false,
        type: "warning",
      })
        .then(() => {
          location.reload();
        })
        .catch(() => {});
      return false;
    }

    return res.data;
  },
  (error) => {
    tryHideFullScreenLoading();
    message.error(errMsg(error));
    return Promise.reject(error);
  }
);

export default service;
