let base_Url = "http://test.nethool.cn";
//商户服务
let merSer = "/merchantadminservice";
//订单服务
let orderSer = "/orderpayservice";
//管理后端服务
let adminSer = "/adminservice";
//埋点服务
let trackSer = "/rocketmqproducerservice";
//客户服务
let cusSer = "/miniprogramservice";

if (process.env.NODE_ENV === "development") {
  // base_Url = "http://192.168.0.109:9000";
  // 振阳
  // merSer = "/merchantadminlzy";
  // orderSer = "/orderpaylzy";
  // cusSer = "/miniprogramlzy";
  // 相国
  // merSer = "/merchantadminlxg";
  // orderSer = "/orderpaylxg";
  // cusSer = "/miniprogramlxg";
  // adminSer = "/adminlxg";
  // 姜冲
  // merSer = "/merchantadminjcc";
  // orderSer = "/orderpayjcc";
  // cusSer = "/miniprogramjcc";
  // base_Url = "http://ai.nethool.cn";
} else if (process.env.NODE_ENV === "test") {
  base_Url = "http://test.nethool.cn";
} else if (process.env.NODE_ENV === "production") {
  base_Url = "http://ai.nethool.cn";
}

module.exports = {
  base_Url,
  merSer,
  orderSer,
  adminSer,
  trackSer,
  cusSer,
};
