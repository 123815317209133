<template>
	<div id="app">
		<router-view v-if="isRouterAlive"></router-view>
	</div>
</template>

<script>
export default {
	name: 'app',
	provide() {
		//父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
		return {
			reload: this.reload,
		}
	},
	data() {
		return {
			isRouterAlive: true, //控制视图是否显示的变量
		}
	},
	watch: {
		$route(to, from) {
			// console.log(to, from);
			// this.filterRou(from)
		}
	},
	methods: {
		reload() {
			this.isRouterAlive = false //先关闭，
			this.$nextTick(function () {
				this.isRouterAlive = true //再打开
			})
		},
	},
	created() {
		// 在页面加载时读取sessionStorage里的状态信息
		if (localStorage.getItem('businessStore')) {
			// 存储状态
			this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(localStorage.getItem('businessStore'))))
		}
		// 在页面刷新时将vuex里的信息保存到sessionStorage里
		window.addEventListener('beforeunload', () => {
			localStorage.setItem(
				'businessStore',
				JSON.stringify(this.$store.state)
			)
		})
	},
}
</script>

<style></style>