import request from "@/utils/request.js";
import common from "@/common/common.js";


//任务支付&订单支付&充值
export const paymentApi = (params) => {
  return request({
    url: common.orderSer + "/proxyPayments/unifiedPayment",
    method: "post",
    data: params,
    contentType: "application/json",
  });
};


//任务&订单&主账户充值
export const setPayment = (params) => {
  return request({
    url: common.orderSer + "/proxyPayments/insertPayment",
    method: "post",
    data: params,
  });
};

//主账户给客户充值
export const setPaymentByaId = (params) => {
  return request({
    url: common.orderSer + "/proxyPayments/paymentAccount",
    method: "post",
    data: params,
  });
};

//获取客户余额
export const getAccountMoney = (params) => {
  return request({
    url: common.orderSer + "/financial/getAccountMoney",
    method: "post",
    data: {objectId:params},
  });
};
  
//回收
export const getRecovery = (params) => {
  return request({
    url: common.orderSer + "/financial/recycleFinancial",
    method: "post",
    data: params,
  });
};

  
//可退款余额
export const getBalance = (params) => {
  return request({
    url: common.merSer + "/account/get",
    method: "get",
  });
};

//退款
export const refund = (params) => {
  return request({
    url: common.merSer + "/account/refundOperation",
    method: "post",
    data: params,
  });
};
