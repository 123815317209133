const routes = [
  {
    path: "/highSea",
    name: "highSea",
    component: () =>
      import(/* webpackChunkName: "custom" */ "@/views/custom/highSea.vue"),
    meta: {
      keepAlive: true,
      isBack: false,
      isNav: true,
    },
  },
  {
    path: "/privateDomain",
    name: "privateDomain",
    component: () =>
      import(
        /* webpackChunkName: "custom" */ "@/views/custom/privateDomain.vue"
      ),
    meta: {
      keepAlive: true,
      isBack: false,
      isNav: true,
    },
  },
  {
    path: "/transactionCustomers",
    name: "transactionCustomers",
    component: () =>
      import(
        /* webpackChunkName: "custom" */ "@/views/custom/transactionCustomers.vue"
      ),
    meta: {
      keepAlive: true,
      isBack: false,
      isNav: true,
    },
  },
  {
    path: "/addCustom",
    name: "addCustom",
    component: () =>
      import(/* webpackChunkName: "custom" */ "@/views/custom/customForm.vue"),
  },
  {
    path: "/editCustom",
    name: "editCustom",
    component: () =>
      import(/* webpackChunkName: "custom" */ "@/views/custom/customForm.vue"),
  },
  {
    path: "/customInfo",
    name: "customInfo",
    component: () =>
      import(/* webpackChunkName: "custom" */ "@/views/custom/customInfo.vue"),
  },
];

export default routes;
