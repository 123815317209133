import { paymentApi } from "@/api/paymentApi";
import store from "@/store";

/* 
clipTaskId; //任务ID    任务支付时传递
userId; //当前登录人ID
merchantId; //客户ID  向客户充值、余额支付任务传递   充值商户时传递商户主客户ID
proxyMerchantId; // 主客户ID；商户客户ID  向商户主账户充值、余额支付任务、客户充值传递
money; // WX and ZFB需要支付/充值的金额 需要在线支付的金额  / 抖音订单支付
balance; // 需要余额支付的金额 余额支付的金额 
payMentorRecharge; //  订单类型(1.充值 2.任务)
rechargeType; // 充值对象类型(1.商户 2.兼职 3.客户) 充值类型 任务支付不传
payTwice; // 付款方式(1.全额 2.预付 3.尾款)
source; // 发起支付的来源 1. 商户端 2. 客户端  ...后续可以添加 默认为商户端
paymentType; // 支付方式( 1.支付宝 2.微信 3.商户余额 4.余额微信 5.余额支付宝 6.客户余额（自行充值的余额） 7.客户余额和微信 8.客户余额+支付宝 9.抖音支付 10.客户账户余额(商户向客户充值的余额) 12.抖音 )
callUpPayment; // 调起支付的方式 1. WX-JSAPI 2. WX-NATIVE扫描二维码 3. WX-H5 4. WX-公众号 5.WX-小程序 6. ZFB-pc端网站 7.ZFB-手机网站
companyProductId; // 支付产品 1、剪辑   ...可扩展   充值商户或客户时不需传递此参数  默认为剪辑产品
transactionType; // 交易类型 1.充值 2.退款 3.支出（任务支付时传 3） 4.退回 5.提现 6.佣金 7.抽成 8.回收 9.工具
code; //  微信支付时需要获取用户openid时传递
returnUrl; //  支付成功返回的地址
explain; //  操作说明 充值说明
dyOrderId; 抖音订单 ID  没有不传
*/

export const _paymentApi = (obj) => {
  let info = {
    userId: store.state.userInfo.userId,
    proxyMerchantId: store.state.aId || "",
    source: "1",
    companyProductId: "1",
  };
  obj.money = String(obj.money);
  obj.balance = String(obj.balance);
  let postData = Object.assign(info, obj);
  console.log(postData);

  return new Promise((resolve, reject) => {
    paymentApi(postData).then((res) => {
      resolve(res);
    });
  });
};
