const routes = [
  {
    path: "/toolShare",
    name: "toolShare",
    component: () =>
      import(/* webpackChunkName: "tools" */ "@/views/tools/toolShare.vue"),
    meta: {
      isNav: true,
    },
  },
  {
    path: "/meterSet",
    name: "meterSet",
    component: () =>
      import(/* webpackChunkName: "tools" */ "@/views/tools/meterSet.vue"),
    meta: {
      isNav: true,
    },
  },
];

export default routes;
