import request from "@/utils/request.js";
import common from "@/common/common.js";

/* API */

/**根据角色id，菜单父级 查询拥有操作菜单列表信息
 * roleId
 * parentId
 */
export const getBtnsByParentId = (params) => {
  return request({
    url: common.merSer + "/proxyMenu/getBtnsByParentId",
    method: "post",
    data: params,
  });
};
// 首页任务统计
// export const getTaskNum = (parameter) => get('task/taskStatistical')
/**任务完成统计
 */
export const getTaskStatistical = (params) => {
  return request({
    url: common.merSer + "/task/taskStatistical",
    method: "get",
  });
};

/**任务完成统计
 */
export const getLineEcharts = (params) => {
  return request({
    url: common.merSer + "/clipTask/getTaskFinishCountList",
    method: "get",
    data: params,
  });
};

/**客户数据
 */
export const getMerchantMsgList = (params) => {
  return request({
    url: common.merSer + "/clipTask/getMerchantMsgList",
    method: "get",
    data: params,
  });
};

/**统计数量
 */
export const getTaskNum = (params) => {
  return request({
    url: common.merSer + "/clipTask/getTaskStatusCount",
    method: "get",
    data: params,
  });
};

//根据角色id 查询拥有最顶级业务菜单树状信息
export const getMenusByRoleIdTop = (params) => {
  return request({
    url: common.merSer + "/proxyMenu/getMenusByRoleIdTop",
    method: "post",
    data: { roleId: params },
  });
};
//根据角色id 查询拥有次级业务菜单树状信息
export const getMenusTreeByRoleId = (params) => {
  return request({
    url: common.merSer + "/proxyMenu/getMenusByRoleId",
    method: "post",
    data: params,
  });
};

//通过字典类型查询字典数据
export const getDicByType = (params) => {
  return request({
    url: common.adminSer + "/sysdictionary/getDicByType",
    method: "post",
    data: { dicType: params },
  });
};
// 消息管理
export const getMsgList = (params) => {
  return request({
    url: common.merSer + "/msgInfo/getLimit",
    method: "post",
    data: params,
  });
};

export const getMsgStatus = (params) => {
  return request({
    url: common.merSer + "/msgInfo/updateMsgInfoStatus",
    method: "post",
    // contentType:'application/json',
    data: { ids: params },
  });
};

export const getMsgStatusAll = (params) => {
  return request({
    url: common.merSer + "/msgInfo/updateMsgInfoStatus",
    method: "post",
    // contentType:'application/json',
    data: { ids: params },
  });
};

export const getMerchantListNoPage = (params) => {
  return request({
    url: common.merSer + "/proxyMerchant/getAllMerchantStatus",
    method: "get",
    data: params,
  });
};
export const getMerchantMsg = (params) => {
  return request({
    url: common.merSer + "/proxyMerchant/getMerchantById",
    method: "post",
    data: { merchantId: params },
  });
};

export const unreadChatMsg = (params) => {
  return request({
    url: common.merSer + "/clipTask/unreadChatMsg",
    method: "get",
  });
};

/**抖音售后提示
 */
export const afterSale = (params) => {
  return request({
    url: common.merSer + "/clipTask/afterSale",
    method: "post",
    data: { a: "" },
  });
};
