import axios from "axios";
import Vue from "vue";
import router from "@/router";
import store from "@/store";
import { message } from "@/components/resetMessage";

// 接口地址&微信登录
// let url = 'http://n.nethool.cn/'
let url = "http://test.nethool.cn/";
// 访问地址
let hurl = "http://test.nethool.cn/";
//代理
// axios.defaults.baseURL = url + 'merchantadminservice/'
let baseURL = url + "merchantadminservice/";

//订单
let orderUrl = url + "orderpayservice/";

//后端
let backUrl = url + "adminservice/";

//小程序
let wxUrl = url + "miniprogramservice/";

//埋点服务
let trackUrl = url + "rocketmqproducerservice/";

//上传接口
// let uploadAll = 'http://121.36.70.169/fastDfsUpload/showView/uploadAll'
let uploadAll = "http://upload-z1.qiniup.com";
let uploadOne = "http://121.36.70.169/fastDfsUpload/showView/uploadTemporary";

// 环境的切换配置默认请求地址
if (process.env.NODE_ENV === "development") {
  // 本地环境
  // url = "http://192.168.0.109:9000/";
  // hurl = 'http://192.168.0.14:8082/'
  // hurl = 'http://192.168.0.102:8082/'
  //霍长清
  // baseURL = url + 'merchantadminhcq/'
  // orderUrl = url + 'orderpayhcq/'
  // wxUrl = url + 'miniprogramlhcq/'
  //李相国
  // baseURL = url + "merchantadminlxg/";
  // orderUrl = url + "orderpaylxg/";
  //李振阳
  // baseURL = url + 'merchantadminlzy/'
  // orderUrl = url + 'orderpaylzy/'
  // backUrl = url + 'adminlzy/'
  // wxUrl = url + 'miniprogramlzy/'
  // trackUrl = url + 'rocketmqproducerlzy/'
  // 姜冲冲
  // baseURL = url + 'merchantadminjcc/'
  // orderUrl = url + 'orderpayjcc/'
} else if (process.env.NODE_ENV === "production") {
  // 正式环境
  uploadAll = "http://upload-z1.qiniup.com";
  url = "http://123.60.223.3:9000/"; //接口地址
  hurl = "http://ai.nethool.cn/"; //访问地址
  baseURL = url + "merchantadminservice/";
  orderUrl = url + "orderpayservice/";
  trackUrl = url + "rocketmqproducerservice/";
  backUrl = url + "adminservice/";
} else if (process.env.NODE_ENV === "test") {
  // 测试环境
  url = "http://test.nethool.cn/";
  hurl = "http://test.nethool.cn/";
  baseURL = url + "merchantadminservice/";
  orderUrl = url + "orderpayservice/";
  backUrl = url + "adminservice/";
  uploadAll = "http://upload-z1.qiniup.com";
  trackUrl = url + "rocketmqproducerservice/";
}

Vue.prototype.$hurl = hurl;
Vue.prototype.$uploadAll = uploadAll;
Vue.prototype.$uploadOne = uploadOne;
Vue.prototype.$baseURL = baseURL;

// axios.defaults.baseURL = '/api'  //关键代码
const axioss = axios.create({
  baseURL: baseURL,
  // timeout: 10000,
});
//订单请求接口
const order = axios.create({
  baseURL: orderUrl,
  // timeout: 10000,
});

//小程序请求接口
const wxMini = axios.create({
  baseURL: wxUrl,
  // timeout: 10000,
  // responseType: "blob"
});

//小程序请求接口
const trackAxios = axios.create({
  baseURL: trackUrl,
  // timeout: 10000,
  // responseType: "blob"
});

Vue.prototype.$order = order;

//后端请求接口
const backEnd = axios.create({
  baseURL: backUrl,
  // timeout: 10000,
});

// axios.defaults.timeout = 10000
let ab = [
  function (data) {
    let src = "";
    for (let item in data) {
      src +=
        encodeURIComponent(item) + "=" + encodeURIComponent(data[item]) + "&";
    }
    return src;
  },
];

var urls = [axioss, order, backEnd, wxMini, trackAxios];
urls.map((item) => {
  item.defaults.transformRequest = ab;
  // 请求拦截器
  item.interceptors.request.use(
    (config) => {
      // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token
      const token = store.state.userInfo.token;
      // if (token) {
      // 	config.headers['token'] = token
      // }

      if (config.url == "login/RSAKey" || config.url == "login/login") {
      } else {
        if (token) {
          config.headers["token"] = token;
        }
      }
      config.headers["Content-Type"] =
        config.contentType || "application/x-www-form-urlencoded;charset=UTF-8";
      let dddate = [];
      for (let i in config.data) {
        if (config.data[i] !== null) {
          dddate[i] = config.data[i];
        }
      }
      config.data = dddate;
      return config;
    },
    (error) => {
      return Promise.error(error);
    }
  );
  // 响应拦截器
  item.interceptors.response.use(
    (response) => {
      store.state.pdwlzt = false;
      const res = response.data;
      // console.log(response)
      if (res.code === 600) {
        sessionStorage.setItem("code", res.code);
        sessionStorage.setItem("msg", res.message);
        setTimeout(() => {
          store.state.bindWeixin = true;
          router.replace({
            path: "/login",
          });
        }, 800);
        return false;
      } else {
        return response;
      }
    },
    (error) => {
      /***** 接收到异常响应的处理开始 *****/
      if (error && error.response) {
        // 1.公共错误处理
        // 2.根据响应码具体处理
        switch (error.response.status) {
          case 400:
            error.message = "错误请求";
            break;
          case 401:
            error.message = "未授权，请重新登录";
            break;
          case 403:
            error.message = "拒绝访问";
            break;
          case 404:
            error.message = "请求错误,未找到该资源";
            break;
          case 405:
            error.message = "请求方法未允许";
            break;
          case 408:
            error.message = "请求超时";
            break;
          case 500:
            error.message = "服务器端出错";
            break;
          case 501:
            error.message = "网络未实现";
            break;
          case 502:
            error.message = "网络错误";
            break;
          case 503:
            error.message = "服务不可用";
            // router.push('/nomess')
            store.state.pdwlzt = true;
            break;
          case 504:
            error.message = "网络超时";
            break;
          case 505:
            error.message = "http版本不支持该请求";
            break;
          default:
            error.message = `连接错误${error.response.status}`;
        }
      } else {
        // 超时处理
        if (JSON.stringify(error).includes("timeout")) {
          message.error("服务器响应超时，请刷新当前页");
        }
        // this.$router.push('nomess')
        error.message = "连接服务器失败";
      }
      if (config.url !== "analyticsEvents/insertEvent") {
        message.error(error.message);
      }
      /***** 处理结束 *****/
      //如果不需要错误处理，以上的处理过程都可省略
      // return Promise.resolve(error.response)
      return Promise.reject(error); // 返回接口返回的错误信息
    }
  );
});

/* *
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axioss
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function orderGet(url, params) {
  return new Promise((resolve, reject) => {
    order
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/* *
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, contentType) {
  console.log(contentType);
  return new Promise((resolve, reject) => {
    axioss
      .post(url, params, {
        contentType: contentType,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function orderPost(url, params) {
  return new Promise((resolve, reject) => {
    order
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function backEndPost(url, params) {
  return new Promise((resolve, reject) => {
    backEnd
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function backEndGet(url, params) {
  return new Promise((resolve, reject) => {
    backEnd
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function wxPost(url, params) {
  return new Promise((resolve, reject) => {
    wxMini
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function wxGet(url, params) {
  return new Promise((resolve, reject) => {
    wxMini
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function trackPost(url, params) {
  return new Promise((resolve, reject) => {
    trackAxios
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
