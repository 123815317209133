<template>
	<el-breadcrumb separator="/">
		<el-breadcrumb-item v-if="value1">{{ value1 }}</el-breadcrumb-item>
		<el-breadcrumb-item>
			<span id="value0">{{ title || this.$store.state.active.menuName }}</span>
		</el-breadcrumb-item>
		<el-breadcrumb-item v-if="value2">
			<span id="value2">{{ value2 }}</span>
		</el-breadcrumb-item>
		<el-breadcrumb-item v-if="value3">
			<span id="value3">{{ value3 }}</span>
		</el-breadcrumb-item>
	</el-breadcrumb>
</template>
<script>
// 自定义组件  面包屑
export default {
	props: ['title', 'value1', 'value2', 'value3'],
	watch: {
		$route(to, from) {
			this.setRou()
		}
	},
	methods: {
		setRou() {

			// this.getMenu(this.active)
		},
	}
};
</script>
