import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/global.less";
import "jquery";

import "@/request/http.js";

import ElementUI from "element-ui";
import validated from "@/components/validated.js";
Vue.use(validated);

import dragVerify from "vue-drag-verify2";
Vue.use(dragVerify);

//埋点指令
import track from "@/components/track.js";
Vue.prototype.$track = track;

import common from "@/common/common.js";
Vue.prototype.$common = common;

import { _paymentApi } from "@/common/pays.js";
Vue.prototype.$pays = _paymentApi;

import { _dict } from "@/common/getDict.js";
Vue.prototype.$dict = _dict;

import { message } from "@/components/resetMessage";
Vue.prototype.$message = message; //重写message提示框  一定要放在Vue.use(ElementUI)之后

import directives from "@/plugins/directive.js";
Vue.use(directives);

import { createSocket, sendWSPush } from "@/utils/websocket.js";
createSocket();
Vue.prototype.$sendWS = sendWSPush;

// import myMenu from "@/components/myMenu.vue";
// Vue.component("myMenu", myMenu); //注册组件

// import "default-passive-events";
// import eIconPicker from "e-icon-picker";
// import "e-icon-picker/lib/symbol.js"; //基本彩色图标库
// import 'e-icon-picker/lib/index.css'; // 基本样式，包含基本图标
// import 'font-awesome/css/font-awesome.min.css'; //font-awesome 图标库
// import 'element-ui/lib/theme-chalk/icon.css'; //element-ui 图标库

//全局删除增加图标
// Vue.use(eIconPicker, {
//   FontAwesome: true,
//   ElementUI: true,
//   eIcon: true, //自带的图标，来自阿里妈妈
//   eIconSymbol: true, //是否开启彩色图标
//   addIconList: [],
//   removeIconList: [],
//   zIndex: 3100, //选择器弹层的最低层,全局配置
// });

const VUE_APP_VERSION = require("../package.json").version;
const vers = window.localStorage.getItem("appVersion");
if (VUE_APP_VERSION != vers) {
  localStorage.removeItem("appVersion");
  window.localStorage.setItem("appVersion", VUE_APP_VERSION);
  location.reload();
}
import directive from "./directive"; // directive
Vue.use(directive);
Vue.config.productionTip = false;
// 跳转后返回顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
Vue.directive("prevent-right", {
  bind: function (el, binding, vnode) {
    // 禁用右键

    el.oncontextmenu = function (event) {
      event.preventDefault(); // 阻止默认事件行为

      return false;
    };

    // 禁用选择

    el.onselectstart = function (event) {
      event.preventDefault(); // 阻止默认事件行为

      return false;
    };

    //禁止F12

    window.onkeydown =
      window.onkeyup =
      window.onkeypress =
        function (event) {
          // 判断是否按下F12，F12键码为123

          if (event.keyCode == 123) {
            event.preventDefault(); // 阻止默认事件行为

            return false;
          }
        };
  },
});
