<template>
    <div v-if="show">
        <el-dialog title="" :visible.sync="show" :width="width" :before-close="beforeClose"
            :close-on-click-modal="false" :close-on-press-escape="false" custom-class="popupNew">
            <div slot="title">
                {{ title }}
            </div>
            <div class="content">
                <slot></slot>
            </div>

            <div slot="footer" :style="{ 'padding-bottom': paddingBottom }">
                <slot name="btns"></slot>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: '470px'
        },
        title: {
            type: String,
            default: '提示'
        },
        paddingBottom: {
            type: String,
            default: '32px'
        },
    },
    methods: {
        // 弹窗关闭
        beforeClose() {
            this.$emit('close')
        }
    }



}
</script>

<style lang="less" scoped>
/deep/.popupNew {
    border-radius: 12px;

    .el-dialog__header {
        padding: 24px 32px 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        font-style: normal;
        border-bottom: 1px solid #f6f6f6;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .el-dialog__headerbtn {
            position: static;
            margin-right: -5px;

            .el-dialog__close {
                color: #ccc;
                font-weight: bold;
                font-size: 18px;
            }
        }
    }

    .el-dialog__body {
        padding: 16px 32px 32px;
        font-size: 14px;
        color: #333333;
        font-family: PingFangSC, PingFang SC;
    }

    .el-dialog__footer {
        padding: 32px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .el-button+.el-button {
        margin-left: 12px;
    }

    .btn {
        width: 300px;
    }

}
</style>