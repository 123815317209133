import request from "@/utils/request.js";
import common from "@/common/common.js";

/* API */

/**新增埋点事件
 */
export const eventLog = (params) => {
  return request({
    url: common.trackSer + "/analyticsEvents/insertEvent",
    method: "post",
    data: params,
  });
};
