import { getDicByType } from "@/api/indexApi";
export const _dict = (obj) => {
  return new Promise((resolve, reject) => {
  /*   getDicByType(obj).then((res) => {
      resolve(res.data);
    });
    return */
    let data = sessionStorage.getItem(obj);
    if(data){
      resolve(JSON.parse(data));
    }else{
      getDicByType(obj).then((res) => {
        resolve(res.data);
        sessionStorage.setItem(obj, JSON.stringify(res.data));
      });
    }
  });
};
