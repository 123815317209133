import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import va from '@/components/validated'

import {
	eventLog
} from '@/api/trackApi'

// 埋点指令
let track = Vue.directive('track', {
	bind: (el, binding, vnode) => {
		el.addEventListener('click', () => {
			const data = binding.value;
			// console.log('浏览器：' + va.browser());
			// console.log('时间：' + va.dataView(new Date()));
			// console.log('IP：' + window.returnCitySN['cip']);
			// console.log('路由：' + router.history.current.name);
			// // request API

			// standardId: '320', //埋点id
			// port: '1', //端口 文字
			// platform: '1', //平台文字
			// module: '1', //模块文字
			// functionType: '1', //功能类型 文字
			// userId: '1', //用户id
			// clipTaskId: '1', //任务id
			// clipPartTimeTaskId: '1', //兼职任务id
			// remarks: '1' //备注  fileId , 分享的视频链接地址
			// port: 'PC端' //端口 文字
			// platform: '商户PC端' //平台文字
			// userId: '1' //用户id
			let info = {
				port: 'PC端', //端口 文字
				platform: '商户PC端', //平台文字
				userId: store.state.userInfo.userId
			}

			let postData = Object.assign(info, data);

			console.log(postData);


			eventLog(postData).then(res => {
				// console(response.data);
			});

		}, false);
	}
})

export default track
