<template>
    <button class="ah-button" ref="ahButton" @click="handleClick" :disabled="buttonDisabled || loading"
        :autofocus="autofocus" :type="nativeType" :class="[
        type ? 'ah-button--' + type : '',
        buttonSize ? 'ah-button--' + buttonSize : '',
        {
            'is-disabled': buttonDisabled,
            'is-loading': loading,
            'is-plain': plain,
            'is-round': round,
            'is-circle': circle
        }, width ? 'ah-button--' + width : ''
    ]">
        <i class="el-icon-loading" v-if="loading"></i>
        <i :class="icon" v-if="icon && !loading"></i>
        <span v-if="$slots.default">
            <slot></slot>
        </span>
    </button>
</template>
<script>
export default {
    name: 'AhButton',

    inject: {
        elForm: {
            default: ''
        },
        elFormItem: {
            default: ''
        }
    },
    props: {
        type: {
            type: String,
            default: 'default'
        },
        size: String,
        icon: {
            type: String,
            default: ''
        },
        nativeType: {
            type: String,
            default: 'button'
        },
        loading: Boolean,
        disabled: Boolean,
        plain: Boolean,
        autofocus: Boolean,
        round: Boolean,
        circle: Boolean
    },

    computed: {
        _elFormItemSize() {
            return (this.elFormItem || {}).elFormItemSize;
        },
        buttonSize() {
            return this.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
        },
        buttonDisabled() {
            return this.$options.propsData.hasOwnProperty('disabled') ? this.disabled : (this.elForm || {}).disabled;
        },
    },
    data() {
        return {
            width: ''
        }
    },
    mounted() {
        let len = this.$refs.ahButton.innerText.length;
        this.width = this.btnAhCN(len);
    },
    updated() {
        let len = this.$refs.ahButton.innerText.length;
        this.width = this.btnAhCN(len);
    },
    methods: {
        handleClick(evt) {
            this.$emit('click', evt);
        },

    }
};
</script>